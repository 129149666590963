<template>
  <div class="settings">
    <transition name="modal" v-if="isVideoModal">
      <div  @click="isVideoModal = false" class="modal-mask"  style="overflow-y: scroll; padding-top: 100px; background-color: rgba(0, 0, 0, .8)!important; ">
        <div class="modal-container" style="width: 900px !important; padding:0!important;; max-height: 506px">

          <div >
            <iframe
              allowfullscreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              width="900" height="506"
              frameborder="0"
              scrolling="no"
              :src="'https://api.bionluk.com/general/video/' +  '10727531'+'x'+ '10727531' +'/custom_video/'"
            >
            </iframe>
          </div>

        </div>
      </div>
    </transition>

    <div v-show="!sectionLoading" style="width: 100%;margin-bottom: 400px; z-index: 2">

        <div class="create-area" v-if="step === 1">
          <p class="title">Hangi kategoride hizmet vermek istiyorsun?</p>
          <p class="sub-title">Hadi, başlayalım. 😎 Yeteneğini sergilemek istediğin işin kategorisini belirle.</p>
          <p class="sub-title" style="font-size: 16px; margin-top: 10px">Nasıl iş ilanı oluşturacağını merak mı ediyorsun? Haydi gel kısa bir video ile nasıl iş ilanı oluşturacağına <span @click="openVideoModal()" class="hover-gg">bir göz atalım!</span></p>

          <div class="main-cats-container">
            <div class="main-cats" v-for="cat in parentCategories">
              <div @click="selectCat(cat)" class="main-cats-bg" :style="cat.id === selectedCat.id ? 'color:white;background-image: url('+cat.square_png_hovered+')' : 'color:#5e6b79;background-image: url('+cat.square_png+')'">
                <p class="cat-title" :style="cat.id === selectedCat.id && cat.id === 3 ? 'color: #2d3640' : ''">{{cat.name}}</p>
                <p class="cat-sub-title" :style="cat.id === selectedCat.id && cat.id === 3 ? 'color: #2d3640' : ''"><span>{{cat.user_count | number_format}}</span> Freelancer</p>
                <p class="cat-sub-title" :style="cat.id === selectedCat.id && cat.id === 3 ? 'color: #2d3640' : ''"><span>{{cat.gig_count | number_format}}</span> Aktif İş İlanı</p>
              </div>
            </div>
          </div>
        </div>
        <div class="create-area" v-if="step === 2">
          <p class="title">Biraz daha detay alalım!</p>
          <p class="sub-title">Peki, hizmet verdiğin uzmanlık alanı <span>{{selectedCat.name}}</span> kategorisinin hangi alanına giriyor?</p>
          <div class="step-2-container">
            <div class="header-img" :style="'background-image:url(https://gcdn.bionluk.com/site/cicons/catImg/squares/bgu_'+selectedCat.id+'.png)'">
              <div class="step-2-text-container" :style="selectedCat.id === 3  ? 'color:#2d3640' : 'color:white'">
                <p class="step-2-title">{{selectedCat.name}}</p>
                <p class="step-2-sub-title">Bu kategoride hizmet veren <span>{{selectedCat.user_count | number_format}} freelancer</span> var.</p>
              </div>

              <div  v-if="selectedCat.cat_users.length" style="display: flex; margin-right: 30px;">
                <div v-for="onlineUser in selectedCat.cat_users ">
                  <div>
                    <router-link
                      :to="'/'+onlineUser.username">
                      <img :alt="onlineUser.username" style="border:1px solid #bfc8d2; width: 44px; height: 44px; border-radius: 50%; margin-left: -15px;" :src="onlineUser.avatar_url">
                    </router-link>
                  </div>
                </div>
                <div style="width: 45px;height: 45px;border: solid 1px #ffffff;background-color: #eaedf2; border-radius: 50%; font-size: 14px; margin-left: -15px; display: flex; align-items: center; justify-content: center;letter-spacing: -0.43px; color: #2d3640; font-weight: bold">
                  +{{selectedCat.user_count - 5 }}
                </div>
              </div>

            </div>

            <div class="sub-cat-box-container">
              <div @click="selectSubCat(subCategory)" :class="selectedSubCat.id === subCategory.id ? 'sub-cat-box-active' : 'sub-cat-box'" v-for="(subCategory, ii) in selectedCat.subCategory">
                <span>{{subCategory.name}}</span>
              </div>
            </div>


            <div class="scroll-into-types"></div>
            <div id="sub-cat-type-kind-container" v-if="showStep2TypeArea">
              <div v-if="selectedSubCat.category_service_types && selectedSubCat.category_service_types.length" class="sub-cat-type-kind-container">
                <p class="title">Hizmet Türü</p>
                <div style="display: flex; align-items: flex-start">
                  <img style="margin-top: 7px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
                  <p class="help-text"><span>{{selectedSubCat.name}}</span> kategorisinde vereceğin hizmet türü hangisi?</p>
                </div>

                <div class="sub-cat-box-container">
                  <select class="super-drop-c" v-model="selectedServiceType" :style="selectedServiceTypes.length > 0 ? 'color:#2d3640;border-color:#00a575;margin-top: 0': 'margin-top: 0'">
                    <option :value="null" disabled style="color: #8b95a1">Seçin...</option>
                    <option :value="option.id" v-for="option in selectedSubCat.category_service_types">{{option.name}}</option>
                  </select>
                </div>
              </div>

              <div v-if="showMetaOptions"  class="sub-cat-type-kind-container">
                <p class="title">İş İlanı Temel Bilgiler</p>
                <div style="display: flex; align-items: flex-start">
                  <img style="margin-top: 7px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
                  <p class="help-text" >Alıcıların seni <span>daha kolay bulması</span> için alttaki bilgileri dikkatle doldurmalısın.</p>
                </div>


                <div class="meta-options-box">
                  <div class="meta-options-right">
                    <div v-if="reRender && !meta_option.dontShow" @click="selectMetaOptionBox(mindex)" v-for="(meta_option, mindex) in computedMetaOptions"  :key="mindex" :class="meta_option.selected ? 'meta-options-right-row-selected' : 'meta-options-right-row' " >
                      <div class="meta-options-right-row-text" :style="isMetaOptionsSelected(mindex) ? 'color:#5e6b79' : 'color: #fe5b5c;'">
                        <p>{{ meta_option.title_for_seller }} {{isMetaOptionsSelected(mindex) ? '' : '*'}}</p>
                        <div class="check-icon-container" v-show="isMetaOptionsSelected(mindex)">
                          <img class="check-icon"
                               src="https://gcdn.bionluk.com/site/cicons/ic-check.svg"
                               onload="SVGInject(this)"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="reRender" class="meta-options-left">

                    <p class="option-left-title">{{selectedMetaOption.description_for_seller}} <span style="font-weight: 500">{{selectedMetaOption && selectedMetaOption.info && selectedMetaOption.info.max ? '(en fazla '+selectedMetaOption.info.max +' seçenek)' : ''}}</span></p>
                    <div v-if="selectedMetaOption && selectedMetaOption.info" class="meta-options-left-form">
                      <template v-if="selectedMetaOption.info.type === 'radio'">
                        <div @click="selectMetaOption(oindex)" v-for="(option, oindex) in selectedMetaOption.options" class="filter-radio-item">
                          <div :class="option.selected ? 'filter-radio-full' : 'filter-radio-empty'"></div>
                          <p :class="option.selected ? 'filter-radio-label-active' : 'filter-radio-label' ">{{option.title_for_seller}}</p>
                        </div>
                      </template>
                      <template v-if="selectedMetaOption.info.type === 'checkbox'">
                        <div @click="selectMetaOption(oindex)" v-for="(option, oindex) in selectedMetaOption.options"  class="filter-checkbox-item" style="display: flex" >
                          <custom-check
                            :container-style="'width:20px; height:20px;'"
                            :check-mark-style="'width:20px; height:20px; border-radius:4px; border: solid 1px #8b95a1;'"
                            called-from=""
                            :single-gig="{}"
                            :id="option.id + '__' + option.title"
                            :checked="option.selected"
                            :disabled="!option.selected && isMetaOptionsDisabled()"
                            :value="option.selected"
                            @input="selectMetaOption(oindex)"
                          />
                          <div :class="option.selected ? 'filter-checkbox-label-active' : isMetaOptionsDisabled() ? 'filter-checkbox-label-disabled' : 'filter-checkbox-label'">{{option.title_for_seller}}</div>
                        </div>
                      </template>
                      <template v-if="selectedMetaOption.info.type === 'selectbox'">
                        <div style="margin-top: 30px">
                          <select id="sel" class="super-drop" v-model="dummyMetaOption">
                            <option :value="null" disabled style="color: #8b95a1">Seç</option>
                            <option :value="option.id" v-for="(option, oindex) in selectedMetaOption.options">{{option.title_for_seller}}</option>
                          </select>
                        </div>
                      </template>
                    </div>



                  </div>
                </div>


              </div>


            </div>



          </div>
        </div>
        <div class="create-area" v-if="step === 3">
          <p class="title">Başlık ve Açıklama</p>
          <p class="sub-title">Harika! Şimdi ilanını oluşturmaya başlayabilirsin. 🚀</p>


          <div v-if="showInfo" class="finance-info for-seller">
            <div @click="showInfo = false" style="position: absolute; top: 15px; right: 15px; cursor: pointer">
              <img src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" onload="SVGInject(this)" class="delete-icon"/>
            </div>
            <div style="margin-left: 20px">
              <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-seller-icon"/>
            </div>
            <div style="margin-left: 15px">
              <div class="description">
                <p class="disapproval-row">Bionluk bazı hizmetlerin satışı için uygun bir platform değildir. Lütfen ilanını oluşturmadan önce <a class="link" href="https://bionluk.com/destek/kategori/is-ilani-kurallari-41" target="_blank"> izin verilmeyen hizmetler</a> bölümüne göz at. 👀</p>
              </div>
            </div>
          </div>




          <p style="margin-top: 40px; margin-bottom: 10px; font-weight: 500; color: #2d3640; font-size: 26px">Etkileyici bir başlık bul!</p>
          <div style="display: flex; align-items: flex-start; margin-bottom: 30px">
            <img style="margin-top: 7px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
            <p style="margin-left:5px;color: #5e6b79; line-height: 1.63;font-size: 16px;">Başlık "Ben... " ile başlayan bir cümle olmalı ve bir fiille bitmelidir. Başlığın sonunda herhangi bir noktalama işareti kullanmamalısın.</p>
          </div>
          <p class="title-static-text">Ben, </p>
          <input maxlength="60" :style="title ? 'border: solid 1px #00a575;text-indent:42px;width:843px;' : 'width:843px;text-indent:42px;'" v-model="title" class="cuper-input-normal" placeholder="uzman olduğum bir konuda...">
          <div style="color: #8b95a1; font-size: 13px; font-weight: 500;display: flex; justify-content: flex-end; margin-top: 10px;">
            {{title.length}} / <span style="color: #2d3640"> 60</span>
          </div>
          <p style="margin-top: 60px; margin-bottom: 30px; font-weight: 500; color: #2d3640; font-size: 26px">Yapacağın işin detaylarını minimum 150 karakterle açıkla</p>
          <textarea maxlength="2500" v-model="description" :style="description ? 'border: solid 1px #00a575;  width: 828px; height: 200px;' : ' width: 828px; height: 200px;'"   class="c-text-area" placeholder="Minimum 150 karakter olacak şekilde nasıl bir hizmet sunduğunu detaylı olarak belirt. İş ilanını ne kadar net yazarsan, alıcıların dikkatini de o kadar çekmiş olursun."></textarea>
          <div style="color: #8b95a1; font-size: 13px; font-weight: 500;display: flex; justify-content: flex-end; margin-top: 10px;">
            <span :style="description.length < 150 ? 'color:#fd4056' : ''">{{description.length}} </span> / <span style="color: #2d3640"> 2500</span>
          </div>
          <p style="margin-top: 60px; margin-bottom: 10px; font-weight: 500; color: #2d3640; font-size: 26px">Siparişe başlaman için gerekenler</p>
          <div style="display: flex; align-items: flex-start; margin-bottom: 30px">
            <img style="margin-top: 7px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
            <p style="margin-left:5px;color: #5e6b79; line-height: 1.63;font-size: 16px;">Bu alana bir sipariş aldığında, siparişe başlamak için ihtiyaç duyduğun bilgileri yazabilirsin. Örneğin; bir çeviri hizmeti veriyorsan, bu alanda alıcıya çevrilmesi gereken metni iletmesini isteyebilirsin.</p>
          </div>
          <textarea maxlength="500" v-model="requirements" :style="requirements ? 'border: solid 1px #00a575;  width: 828px; height: 150px;' : ' width: 828px; height: 150px;'"   class="c-text-area" placeholder="Ödeme sonrasında işe başlamak için alıcıdan ne tür bilgilere veya dosyalara ihtiyacın var? Bu alanda detaylı olarak belirtebilirsin."></textarea>
          <div style="color: #8b95a1; font-size: 13px; font-weight: 500;display: flex; justify-content: flex-end; margin-top: 10px;">
            {{requirements.length}} / <span style="color: #2d3640"> 500</span>
          </div>
        </div>
        <div class="create-area" v-if="step === 4">
          <p class="title">Fiyatlandırma</p>
          <p class="sub-title">Verdiğin hizmeti fiyatlandır. Eğer verdiğin hizmet karşılığında nasıl bir fiyat vereceğin konusunda emin değilsen seninle aynı hizmeti veren diğer freelancer’ların ilanlarına bakarak ideal bir fiyat belirleyebilirsin.️</p>


          <div class="hiddendiv"></div>
          <div class="hiddendiv20"></div>

          <div style="background: white; padding-top: 50px;">
            <div style="background: white; padding: 20px; width: 833px">
              <pricing :gigPackageItems="gigPackageItems" :gigDefaultExtras="gigDefaultExtras" :extraWordObject="extraWordObject" ref="pricing" :packages="packages" :categorySubId="selectedSubCat.id"></pricing>
            </div>
          </div>



        </div>
        <div class="create-area" v-if="step === 5">
          <p class="title">Vitrin</p>
          <p class="sub-title">İş ilanının daha fazla ilgi çekmesi için en az <span>görsel, video veya ses</span> eklemelisin. Resim dosyaları için <span>maksimum boyut 2 mb</span>; video ve ses dosyaları için <span>maksimum boyut 300 mb ve 90 saniyedir.</span>️</p>

          <div v-if="files.length === 0" @click="openUploadModal(null,null)" class="upload-box">
            <button style="width: 220px; height: 54px;" class="cuper-black-button">
              <img src="https://gcdn.bionluk.com/site/cicons/ic-round-plus.svg"  onload="SVGInject(this)" class="upload-plus-icon">
              <span style="padding-left: 14px;">Dosya Ekle</span>
            </button>
            <div class="help-text">
              Ekleyebileceğin dosya tipleri: <span>JPG, PNG, .MP4, .MP3</span> <span class="emoji">📸 📽 🎙</span>
            </div>
          </div>

          <div class="items" style="margin-top: 60px; display: flex; flex-wrap: wrap; margin-left: -47px">
            <div  class="gallery-photos-container" style=" display: flex; flex-wrap: wrap;">
              <div v-for="(file, fi) in files" class="item" style="margin-left: 47px" :key="file.uuid">
                <div v-if="file.file_type === 'audio'" class="video-sound-icon-container">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="video-sound-icon">
                </div>
                <div v-if="file.file_type === 'video'" class="video-sound-icon-container">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="video-sound-icon">
                </div>
                <img class="item-img" :src="file.cover.image_url_small">
                <div :class="fi === 0 ? 'bottom-cover' : 'bottom'">
                  <div v-show="fi === 0" style="display: flex; align-items: center; justify-content: flex-start">
                    <img v-if="fi === 0" src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="star-icon">
                    <p class="bottom-text">Vitrin</p>
                  </div>
                  <div v-show="fi !== 0" style="display: flex; align-items: center; justify-content: flex-start">
                    <div @click="makeCover(fi)" class="make-cover-container">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="star-icon">
                    </div>
                  </div>
                  <div style="display: flex;">
                    <div @click="openUploadModal(file, fi)" class="delete-icon-container"><img src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg" onload="SVGInject(this)" class="delete-icon"></div>
                    <div @click="removeUpload(fi)" class="delete-icon-container"><img src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon"></div>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>

    </div>

    <div v-show="sectionLoading" style="width: 850px; margin:0 auto; height: 400px; display: flex; align-items: center; justify-content: center">
      <loader></loader>
    </div>


    <div class="bottom-fixed-div">
      <div class="bottom-centered">
        <div style="display: flex">
          <div style="width: 300px;">
            <p class="step-title">Adım {{step}} / 5</p>
            <p class="step-sub-title">{{stepName}}</p>
          </div>
          <div v-if="selectedCat.id" style="display: flex; height: 46px; margin-right:20px; align-items: center">
            <div  @click="goStep(1)" class="bottom-cat-img-container" :style="'background:'+selectedCat.cat_color">
              <img class="bottom-cat-img" :src="'https://gcdn.bionluk.com/site/cicons/catImg/squares/sm_'+selectedCat.id+'.png'">
            </div>
            <p @click="goStep(1)" class="bottom-cat-title">{{selectedCat.name}}</p>
          </div>
          <div @click="goStep(2)" v-if="selectedSubCat.id" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            <div style="display: flex;justify-content: center;">
              <p >{{selectedSubCat.name}}</p>
              <div v-if="showTooltip" style="position:absolute;width: 178px; height: 55px;margin-top: -120px;">
                <div class="arrow_box"><p style="padding-left: 15px; padding-right: 15px;">Geri dönerek düzenleme yapmak için burdaki adımları kullanabilirsin.</p></div>
                <div class="a-outer"><div class="a-inner">&nbsp;</div></div>
              </div>
            </div>
          </div>
          <div @click="goStep(3)" v-if="title" style=" cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            <div style="max-width:164px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">Ben, {{title}}</div>
          </div>
          <div @click="goStep(4)" v-if="priceShowText" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            {{priceShowText}}
          </div>
        </div>
        <div @click="nextStep" :class="enableButton ? 'continue-button-active' : 'continue-button'">
          {{step === 5 ? serviceLoading ? 'Yayınlanıyor...' :  'Bitir ve Yayınla'  : 'Devam Et'}}
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import draggable from 'vuedraggable';
  import pricing from '../../../../components/PricingComponent';
  import loader from "@/pages/components/toolbox/loader";
  import CustomCheck from "@/pages/components/CustomCheck";
  export default {
    name: "src-pages-body-workstation-portfolio-v4",
    components: {
      draggable, pricing, loader, CustomCheck
    },
    data() {
      return {
        isVideoModal:false,
        serviceLoading:false,
        hideTooltip:false,
        buttonLoading:false,
        selectedCat:{},
        selectedSubCat:{},
        selectedServiceTypes:[],
        selectedServiceKinds:[],
        selectedServiceKind:null, //sadece çeviri için şu an hackk
        selectedServiceType:null, //fiyatlamadan değişirse kullanılıyor


        selectedMetaOption:{},
        dummyMetaOption:null,

        step:1,
        files:[],

        tempStep:null,
        priceShowText:'',

        uuid: null,
        title:'',
        description:'',
        requirements:'',


        gigPackageItems: [],
        gigDefaultExtras: [],
        extraWordObject: {},
        serviceTypeChanger: false,
        packages: {},

        showSesDilFilter:false,

        reRender:true,

        sectionLoading:false,
        showInfo:true

      }
    },

    methods: {

      openVideoModal(){
        this.isVideoModal = true;
        this.Helper.trackEvents.customEvent("custom_video_view", {title:'yeni_ilan', page:this.$store.state.componentMap.page});
      },

      isMetaOptionsSelected(i){
        let isSelected = false;
        this.selectedSubCat.meta_options.forEach((o,oi) => {
          o.options.forEach( (oo,ooi) => {
            if(i === oi && oo.selected){
              isSelected = true
            }
          })
        });

        return isSelected;
      },

      isMetaOptionsDisabled(){
        let isDisabled = false;

        const foundIndex = this.selectedSubCat.meta_options.findIndex(option => option.selected)
        if(foundIndex > -1 && this.selectedSubCat.meta_options[foundIndex]?.info?.max){
          let currentOptions = this.selectedSubCat.meta_options[foundIndex].options;
          if(currentOptions){
            let count = 0;
            currentOptions.forEach( (oo,ooi) => {
              if(oo.selected){
                count++;
              }
            })
            if(this.selectedSubCat.meta_options[foundIndex].info.max <= count){
              isDisabled = true;
            }
          }
        }


        return isDisabled;

      },

      selectMetaOptionBox(index){
        this.selectedSubCat.meta_options.forEach((m,i) => {
          if(index === i){
            m.selected = true;
            this.selectedMetaOption = m;
          } else {
            m.selected = false;
          }

        });

        this.reRender = false;
        this.reRender = true;
      },

      sesDilTemizle(){
        this.showSesDilFilter = false;
        this.selectedServiceKinds = [];
        this.selectedServiceKind = null;
        this.showSesDilFilter = true;
      },
      sesDilUygula(){
        this.showSesDilFilter = false;
        this.showSesDilFilter = true;
      },
      selectMultiKind(kind){
        this.showSesDilFilter = false;
        const foundIndex = this.selectedSubCat.category_service_kinds.options.findIndex(option => option.id === kind.id)
        if(foundIndex > -1){
          let arr = this.selectedSubCat.category_service_kinds;
          arr.options[foundIndex].selected = !this.selectedSubCat.category_service_kinds.options[foundIndex].selected;
          this.selectedSubCat.category_service_kinds = arr;
        }
        this.showSesDilFilter = true;
      },

      handleFocusOut(what='gender'){
        if(what === 'dil'){
          this.showSesDilFilter = false;
        }
      },
      showSesGender(){
        this.showSesDilFilter = true;
        this.$nextTick(() => {
          document.getElementById("ses-dil-container").focus()
        })
      },

      openUploadModal(sendFile=null, fileIndex=null){
        if(!sendFile) sendFile = {upload_id:null};
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.UPLOAD_GALERY, info: {from:'gig', sendFile, fileIndex}});
      },


      removeUpload(index){

        this.files.splice(index,1);
      },

      addPortfolioItem(payload){
        if(payload.calledFrom && payload.calledFrom === 'edit'){
          let fileIndex = payload.fileIndex;
          let tempFiles = this.files;
          let tempFile = payload.file;
          tempFiles[fileIndex] = tempFile;
          this.files =  JSON.parse(JSON.stringify(tempFiles));
        } else {
          this.files = this.files.concat(payload.file);
        }
      },

      goStep(step){
        if(step === 2 && this.showTooltip){
          this.hideTooltip = true
        } else {
          if(this.step === 4 && step !== 4){
            this.tempStep = step;
            this.EventBus.$emit('pricingRequestData', 'sendInfo');
          } else {
            this.step = step;
            window.scrollTo(0,0);
          }
        }
      },

      createGig() {

        this.sectionLoading = true;
        this.buttonLoading = true;
        let selectedMetaOptions = [];
        this.selectedSubCat.meta_options.forEach(o => {
            let selectedOptions = [];
            let hasIt = false;
            o.options.forEach(oo => {
              if(oo.selected){
                hasIt = true;
                selectedOptions.push(oo.id)
              }
            })
          if(hasIt){
            selectedMetaOptions.push({
              id:o.id,
              options:selectedOptions
            })
          }
        });

        let gigInfo = {
          category_id: this.selectedCat.id,
          category_sub_id: this.selectedSubCat.id,
          title: this.title,
          description: this.description,
          requirements: this.requirements,
          gig_service_types: this.selectedServiceTypes.length ? this.selectedServiceTypes[0] : null,
          gig_service_kinds: this.selectedServiceKinds,
          gig_meta_options: JSON.stringify(selectedMetaOptions)
        };

        this.api.seller.createGig(gigInfo, this.$Progress)
          .then(async ({data}) => {
            let result = data;
            if (result.success) {
              this.uuid = result.data.uuid;
              //this.getPortfolios();

              await this.getGigPackageItems();
              await this.getGigDefaultExtras();
              await this.getGigPackages();

            } else {
              this.step--;
              if(result.data && result.data.showModal){
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.WARNING_MODAL, info: result.data.modal});
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
              this.sectionLoading = false;
              this.buttonLoading = false;
            }
          })
          .catch(err => {
            this.step--;
            this.buttonLoading = false;
            this.$toasted.global.errorToast({description: err});
            this.sectionLoading = false;
          })
      },

      async nextStep(){
        if(this.enableButton){
          if(this.step === 4){
            this.tempStep = 5;
            this.EventBus.$emit('pricingRequestData', 'sendInfo');
          } else if(this.step === 5){

            this.serviceLoading = true;
            let a1 = await this.gigUpdateS1();
            let a2 = null;
            let a3 = null;
            let a4 = null;
            let a5 = null;
            let a6 = null;
            if(a1) { a2 = await this.gigUpdateS3();} else {this.serviceLoading = false; this.sectionLoading = false;}
            if(a2) { a3 = await this.gigUpdateS4();} else {this.serviceLoading = false; this.sectionLoading = false;}
            if(a3) { a4 = await this.gigUpdateCovers();} else {this.serviceLoading = false; this.sectionLoading = false;}
            if(a4) { a5 = await this.changeGigStatus();} else {this.serviceLoading = false; this.sectionLoading = false;}
            if(a5) { a6 = await this.gigUpdateCovers();} else {this.serviceLoading = false; this.sectionLoading = false;}

            if(a6){
              await this.$router.push(a5);
            } else {
              this.serviceLoading = false;
              this.sectionLoading = false;
            }
          } else {
            this.step++;
            window.scrollTo(0,0);
          }
        }
      },

      async gigUpdateS3() {
        let ret = await this.api.seller.gigUpdateS3(this.uuid, this.description, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              return true;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              return false;
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            return false;
          });

        return ret;

      },

      async gigUpdateS4() {
        this.buttonLoading = true;
        let ret = await this.api.seller.gigUpdateS4(this.uuid, this.requirements, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.buttonLoading = false;
              return true;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.buttonLoading = false;
              return false;
            }

          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
            return false;
          });
        return ret;
      },

       async changeGigStatus() {
        this.buttonLoading = true;
        let ret = await this.api.seller.changeGigStatus(this.uuid, 1, this.$Progress)
          .then(({data}) => {
            let result = data;
            this.loading = false;
            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.buttonLoading = false;
              this.serviceLoading = false;
              return result.data.redirect_url;
            } else {
              this.serviceLoading = false;
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: result.message});
              return false;
            }
          })
          .catch(err => {
            this.serviceLoading = false;
            this.buttonLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            return false;
          });
        return ret;
      },
      async initPricing(){
        if(this.uuid){
          //update Gig
          let a1 = await this.gigUpdateS1();
          if(a1){
            await this.getGigPackageItems();
            await this.getGigDefaultExtras();
            await this.getGigPackages();
          } else {
            if(this.tempStep){
              this.step = this.tempStep;
              this.tempStep = null;
            }
          }
        } else {
          await this.createGig();
        }

      },

      async gigUpdateCovers(){
        this.buttonLoading = true;
        let ret = await this.api.seller.gigUpdateContent(this.uuid, this.files[0] ? this.files[0].upload_id : null, this.files[0] ? this.files[0].cover.upload_id : null, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.buttonLoading = false;
              return true;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.buttonLoading = false;
              return false;
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
            return false;
          });

        return ret;
      },


      getGigPackages() {
        this.buttonLoading = true;
        this.sectionLoading = true;
        this.api.seller.getGig(this.uuid, null, 'gigEdit')
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.packages = {...result.data.packages};
              this.buttonLoading = false;
              this.sectionLoading = false;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.buttonLoading = false;
              this.sectionLoading = false;
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
            this.sectionLoading = false;

          });
      },


      async getGigPackageItems() {

        this.buttonLoading = true;
        this.sectionLoading = true;
        const {data} = await this.api.seller.getGigPackageItems(this.selectedSubCat.id, this.selectedServiceTypes.length ? this.selectedServiceTypes[0] : null , this.$Progress);
        const result = data;
        if (result.success) {
          this.gigPackageItems = [...result.data.gig_package_items];
          this.buttonLoading = false;
          this.sectionLoading = false;
        } else {
          this.$toasted.global.errorToast({description: result.message});
          this.buttonLoading = false;
          this.sectionLoading = false;
        }
      },

      async getGigDefaultExtras() {
        this.buttonLoading = true;
        this.sectionLoading = true;
        const {data} = await this.api.seller.getGigDefaultExtras(this.selectedSubCat.id, this.selectedServiceTypes.length ? this.selectedServiceTypes[0] : null, this.$Progress);
        const result = data;
        if (result.success) {
          this.gigDefaultExtras = [...result.data.gig_default_extras];
          this.extraWordObject = result.data.extra_words;
          this.buttonLoading = false;
          this.sectionLoading = false;
        } else {
          this.$toasted.global.errorToast({description: result.message});
          this.buttonLoading = false;
          this.sectionLoading = false;
        }
      },



      selectMetaOption(i=null) {
        let selectedMetaOption = this.selectedMetaOption;
        const foundIndex = this.selectedSubCat.meta_options.findIndex(option => option.selected)
        let currentOptions = this.selectedSubCat.meta_options[foundIndex].options;

        let count = 0;
        currentOptions.forEach(co => {
          if(co.selected){
            count++;
          }
        });
        currentOptions.forEach((o,oi) => {
          if(selectedMetaOption?.info.type === 'radio'){
            o.selected = i === oi;
          } else if(selectedMetaOption?.info.type === 'checkbox'){
            if(i === oi){
              if(selectedMetaOption?.info?.max){
                if(!o.selected){
                  if(selectedMetaOption.info.max > count){
                    o.selected = true;
                  }
                } else {
                  o.selected = false;
                }
              } else {
                o.selected = !o.selected;
              }
            }
          } else if(selectedMetaOption?.info.type === 'selectbox'){
            o.selected = o.id === i;
          }
        });
        this.reRender = false;
        this.reRender = true;
      },

      selectCat(cat){
        this.selectedSubCat = {};
        this.selectedServiceTypes = [];
        this.selectedServiceKinds = [];
        this.selectedServiceKind = null;
        this.selectedServiceType = null;
        this.serviceTypeChanger = false;
        this.selectedCat = cat;
        this.dummyMetaOption = null;
      },

      selectSubCat(subCat){

        if(this.selectedSubCat && this.selectedSubCat.id && subCat && subCat.id && this.selectedSubCat.id === subCat.id){

        } else {

          this.selectedSubCat = subCat;
          this.selectedServiceTypes = [];
          this.selectedServiceKinds = [];
          this.selectedServiceKind = null;
          this.selectedServiceType = null;
          this.serviceTypeChanger = false;
          this.dummyMetaOption = null;

          if(subCat.id === 61){
            subCat.category_service_types = [];
          }


          this.$nextTick(() => {
            if(subCat && ((subCat.category_service_types && subCat.category_service_types.length > 0) || (subCat.meta_options && subCat.meta_options.length > 0))){

              if(subCat.meta_options && subCat.meta_options && subCat.meta_options.length > 0){

                this.selectedSubCat.meta_options.forEach((c,i) => {
                  c.options.forEach((m) => {
                    m.selected = false;
                  })
                  if(i===0){
                    this.selectedMetaOption = c;
                    c.selected = true;
                  } else {
                    c.selected = false;
                  }
                });

                this.reRender = false;
                this.reRender = true;
              }
              document.querySelector('.scroll-into-types').scrollIntoView({
                behavior: 'smooth'
              });
            }
          });

        }
      },

      selectServiceType(id){
        this.selectedServiceTypes = [];
        this.selectedServiceTypes.push(id);
        this.serviceTypeChanger = false;
      },

      selectServiceKind(id){
        if(this.selectedServiceKinds.indexOf(id) > -1){
          this.selectedServiceKinds.splice(this.selectedServiceKinds.indexOf(id),1);
        } else {
          this.selectedServiceKinds.push(id);
        }
      },



      mutatePricingDataForRequest(data) {
        this.buttonLoading = true;
        this.sectionLoading = true;

        const basic = {
          items: []
        };

        const standard = {
          items: []
        };

        const premium = {
          items: []
        };

        const extras = {
          customExtras: [],
          defaultExtras: []
        };
        for (let key in data.basic) {
          const id = key.split('_')[1];
          if (!isNaN(id)) {
            const obj = {
              gig_package_item_id: Number(id),
              value: data.basic[key]
            };
            basic.items.push(obj)
          } else {
            basic[id] = data.basic[key];
          }
        }

        for (let key in data.standard) {
          const id = key.split('_')[1];
          if (!isNaN(id)) {
            const obj = {
              gig_package_item_id: Number(id),
              value: data.standard[key]
            };
            standard.items.push(obj)
          } else {
            standard[id] = data.standard[key];
          }
        }

        for (let key in data.premium) {
          const id = key.split('_')[1];
          if (!isNaN(id)) {
            const obj = {
              gig_package_item_id: Number(id),
              value: data.premium[key]
            };
            premium.items.push(obj)
          } else {
            premium[id] = data.premium[key];
          }
        }
        //extra fast delivery
        basic.extra_fast_delivery = {
          ...data.extraFastDelivery.basic,
          selected: data.extraFastDelivery.selected
        };
        standard.extra_fast_delivery = {
          ...data.extraFastDelivery.standard,
          selected: data.extraFastDelivery.selected
        };
        premium.extra_fast_delivery = {
          ...data.extraFastDelivery.premium,
          selected: data.extraFastDelivery.selected
        };

        //extra words
        basic.extra_words = {
          ...data.extraWords.basic,
          selected: data.extraWords.selected
        };
        standard.extra_words = {
          ...data.extraWords.standard,
          selected: data.extraWords.selected
        };
        premium.extra_words = {
          ...data.extraWords.premium,
          selected: data.extraWords.selected
        };

        data.customExtraGig.forEach(c => {
          extras.customExtras.push(c);
        });

        for (let key in data.extras) {
          const id = key.split('_')[1];
          if (!isNaN(id)) {
            const obj = {
              gig_default_extra_id: Number(id),
              price: data.extras[key].price,
              selected: data.extras[key].selected,
              duration: data.extras[key].duration
            };
            extras.defaultExtras.push(obj)
          } else if(id === 'revision') {
            extras.revisionExtra = {
              price: data.extras[key].price,
              selected: data.extras[key].selected,
              duration: data.extras[key].duration
            };
          }

        }



        const requestObjectf = {
          uuid: this.uuid,
          packageCount: data.enable3Packages ? 3 : 1,
          basic,
          standard,
          premium,
          extras
        };



        const requestObject = {
          uuid: this.uuid,
          packageCount: data.enable3Packages ? 3 : 1,
          basic: JSON.stringify(basic),
          standard: JSON.stringify(standard),
          premium: JSON.stringify(premium),
          extras: JSON.stringify(extras),
          voiceoverOptions: JSON.stringify(data.voiceoverOptions)
        };


        this.api.seller.gigUpdateS2_V2(requestObject, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              if(this.tempStep){
                this.step = this.tempStep
                this.tempStep = null;
              } else {
                this.step++;
              }
              this.priceShowText = 'Temel (' + basic.pricing + 'TL)';
              window.scrollTo(0, 0);
            } else {
              this.step = 4;
              this.tempStep = null;
              this.$toasted.global.errorToast({description: result.message});
            }

            this.buttonLoading = false;
            this.sectionLoading = false;

          })
          .catch(err => {
            this.buttonLoading = false;
            this.sectionLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },


      async gigUpdateS1() {
        this.buttonLoading = true;
        this.sectionLoading = true;

        let selectedMetaOptions = [];
        this.selectedSubCat.meta_options.forEach(o => {
          let selectedOptions = [];
          let hasIt = false;
          o.options.forEach(oo => {
            if(oo.selected){
              hasIt = true;
              selectedOptions.push(oo.id)
            }
          })
          if(hasIt){
            selectedMetaOptions.push({
              id:o.id,
              options:selectedOptions
            })
          }
        });
        let gigInfo = {
          uuid: this.uuid,
          category_id: this.selectedCat.id,
          category_sub_id: this.selectedSubCat.id,
          title: this.title,
          gig_service_types: this.selectedServiceTypes.length ? this.selectedServiceTypes[0] : null,
          gig_service_kinds: this.selectedServiceKinds,
          gig_meta_options: JSON.stringify(selectedMetaOptions)
        };

        let ret = await this.api.seller.gigUpdateS1({...gigInfo}, this.$Progress)
          .then(async ({data}) => {
            let result = data;

            if (result.success) {
              this.buttonLoading = false;
              this.sectionLoading = true;
              return true;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.buttonLoading = false;
              this.sectionLoading = true;
              return true;
            }
          })
          .catch(err => {
            this.buttonLoading = false;
            this.sectionLoading = true;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            return false;
          });

        return ret;
      },

    },

    computed: {
      computedMetaOptions: function (){
        if(this.selectedSubCat?.meta_options?.length > 0){
          if(this.selectedSubCat?.category_service_types?.length > 0 && this.selectedServiceType ){
            this.selectedSubCat.meta_options.forEach((mm,ii) =>{
              mm.dontShow = false
              if(mm.info.excluded_service_types?.length && mm.info.excluded_service_types.indexOf(this.selectedServiceType) > -1){
                mm.dontShow = true
                mm.selected = false;
              }
            })
          }
          this.rerender = false;
          this.rerender = true;
          return this.selectedSubCat.meta_options
        } else {
          return [];
        }
      },
      showMetaOptions(){
        return (this.step === 2 && this.computedMetaOptions && this.computedMetaOptions.length)
        && (this.selectedServiceType || (this.selectedSubCat.category_service_types.length === 0 ) )
      },
      showStep2TypeArea(){

        if(this.step === 2 && this.selectedSubCat && this.selectedSubCat.id &&
            (this.selectedSubCat.category_service_types && this.selectedSubCat.category_service_types.length > 0)
              ||
          (this.selectedSubCat.meta_options && this.selectedSubCat.meta_options.length > 0)
          )
        {
          return true;
        } else {
          return false
        }
      },
      showTooltip: function(){

        if(this.hideTooltip){
          return false
        } else {
          if(this.step > 2){
            return true
          } else {
            return false
          }
        }
      },
      enableButton: function(){
        let p = this.reRender;
        let ret = false;
        if(this.step === 1){
          if(this.selectedCat.id){
            ret = true;
          }
        }
        if(this.step === 2){
          if(this.selectedSubCat.id){
            if(this.showStep2TypeArea){
              let ret_type = true;
              let ret_meta = true;
              if(this.selectedSubCat.category_service_types.length > 0){
                ret_type = false;
                if(this.selectedServiceTypes && this.selectedServiceTypes.length > 0){
                  ret_type = true;
                }
              }
              if(this.selectedSubCat.meta_options && this.selectedSubCat.meta_options.length > 0){

                this.selectedSubCat.meta_options.forEach((o, i) => {
                  o.hasSelectedOption = false;
                  if(o.info?.excluded_service_types?.length > 0 && o.info?.excluded_service_types.indexOf(this.selectedServiceType) > -1){
                    o.hasSelectedOption = true;
                  } else {
                    o.options.forEach((oo) => {
                      if(oo.selected){
                        o.hasSelectedOption = true;
                      }
                    })
                  }
                });

                this.selectedSubCat.meta_options.forEach((k, ki) => {
                  if(!k.hasSelectedOption){
                    ret_meta = false;
                  }
                });
              }

              if(ret_meta && ret_type){
                ret = true;
              }
            } else {
              ret = true;
            }
          }
          this.reRender = true;
        }
        if(this.step === 3){
          if(this.title && this.description && this.description.length > 149 &&  this.requirements){
            ret = true;
          }
        }
        if(this.step === 4){
            ret = true;
        }
        if(this.step === 5 && this.files && this.files.length > 0){
          ret = true;
        }

        if(this.buttonLoading){
          ret = false;
        }
        if(this.serviceLoading){
          ret = false;
        }

        return ret;
      },

      stepName: function () {
        if(this.step === 1){
          return 'Kategori Seçimi';
        }
        if(this.step === 2){
          return 'Alt Kategori Seçimi';
        }
        if(this.step === 3){
          return 'Başlık ve Açıklamalar';
        }
        if(this.step === 4){
          return 'Fiyatlandırma';
        }
        if(this.step === 5){
          return 'Vitrin';
        }
      }
    },

    watch: {
      dummyMetaOption: function (newVal){
        let selectedMetaOption = this.selectedMetaOption;
        if(newVal){
          if(selectedMetaOption?.info?.type === 'selectbox'){
            this.selectMetaOption(newVal)
          }
        }
      },
      selectedServiceKind: function (newVal) {
        if(this.selectedSubCat.id === 38){
          if(newVal){
            this.selectedServiceKinds = [];
            this.selectedServiceKinds.push(newVal);
          } else {
            this.selectedServiceKinds = [];
          }
        }
      },
      selectedServiceType: function (newVal, oldVal) {
        if(this.step === 2 && newVal && newVal !== oldVal){
          let isSelectedMetaOption = false;
          this.selectedSubCat.meta_options.forEach((o,oi) => {
            if(!isSelectedMetaOption){
              if(o.info?.excluded_service_types?.length && o.info?.excluded_service_types.indexOf(this.selectedServiceType) > -1){
                o.selected = false;
              } else {
                this.selectedMetaOption = o;
                o.selected = true;
                isSelectedMetaOption = true;
              }
            } else {
              o.selected = false;
            }

            o.options.forEach( (oo,ooi) => {
              oo.selected = false;
            })
          });
          this.selectedServiceTypes = [];
          this.selectedServiceTypes.push(newVal);
        }
      },
      step:function (newVal, oldVal) {
        if(newVal === 4){
          this.initPricing();
        }

        if(this.uuid && oldVal === 4){
         // this.EventBus.$emit('pricingRequestData', 'sendInfo');
        }
      }

    },

    created() {

      if(!this.user.seller.isRegistered){
        let info = {
          title:'IBAN Bilgisi',
          bodyText:'Banka hesap bilgilerinde eksiklik var. Sana ödeme yapılabilmesi için lütfen "Banka ve Ödeme Bilgileri" sayfasında ödeme bilgilerini güncelle.',
          actionButton:{
            show:true,
            title:'Ayarlar',
            url:'/settings/finance',
            cancelButtonTitle:null
          }
        };
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.WARNING_MODAL, info: info});
      }
      this.EventBus.$on('addPortfolioItem', payload => {
        this.addPortfolioItem(payload);
      });

      this.EventBus.$on('pricingResponseData', payload => {
        if (payload) {
          const data = JSON.parse(JSON.stringify(payload))
          this.mutatePricingDataForRequest(data);
        }
      });
    },

    beforeDestroy() {
      this.EventBus.$off("addPortfolioItem");
      this.EventBus.$off("pricingResponseData");

    }
  }
</script>

<style scoped lang="scss">


.hover-gg{
  color: #2cad87!important;
  font-weight: 600;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

.finance-info{
  width: 843px;
  height: 100px;
  border-radius: 10px;
  border: solid 1px rgba(242, 153, 51, 0.3);
  background-color: rgba(242, 153, 51, 0.2);
  margin-top: 30px;
  display: flex;
  align-items: center;
  position: relative;

  .disapproval-row{
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: #2d3640;
    .link{
      font-weight: 600;
      color: #2D3640;
      text-decoration: underline;
    }
  }

  &.for-seller {
    border: solid 1px rgba(90, 38, 164, 0.3);
    background-color: rgba(90, 38, 164, 0.2);
  }
  .for-seller-icon /deep/{
    width: 36px;
    height: 36px;
    path{
      fill: #5a26a4;
    }
  }
  .for-buyer-icon /deep/{
    width: 36px;
    height: 36px;
    path{
      fill: #f29933;
    }
  }
  .delete-icon /deep/{
    width: 16px;
    height: 16px;
    path{
      fill: #2d3640;
    }
  }
  .title{
    font-size: 16px;
    font-weight: 500;
    color: #343d4a;
    padding-bottom: 4px;
  }
  .description{
    width: 668px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    color: #4f596c;
  }
}


.request-warning{

  width: 843px;
  margin-top: 35px;

  border-radius: 10px;
  border: solid 1px rgba(242, 153, 51, 0.3);
  background-color: rgba(242, 153, 51, 0.2);

  .request-warning-inside{
    display: flex;
    padding: 20px 30px;
  }

  .warning-head{
    font-size: 18px;
    font-weight: 500;
    color: #2d3640;
  }
  .warning-sub-head{


    font-size: 16px;
    font-weight: 400;


    line-height: 1.5;

    color: #2d3640;
  }


  .warning-icon /deep/ {
    width: 35px;
    height: 32px;
    path {
      fill: #f29933;
    }
  }


  .disapproval-row{
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: #2d3640;
    .link{
      font-weight: 600;
      color: #fd4056;
    }
  }

}

.filter-radio-item{
  display: flex; align-items: center; cursor: pointer;
  margin-bottom: 20px;

  .filter-radio-empty{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px #8b95a1;
  }

  .filter-radio-label{
    font-size: 16px;  color: #5e6b79; margin-left: 8px; line-height: 1.4;

  }

  .filter-radio-label-active{
    font-size: 16px; color: #5e6b79; font-weight: 400; margin-left: 8px; line-height: 1.4
  }

  .filter-radio-full{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: solid 3px #00a575;
  }


}


.filter-checkbox-item{
  cursor: pointer;
  display: flex;
  width: 50%;
  margin-bottom: 24px;


  .filter-checkbox-disabled{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: solid 1px #8b95a1;
    opacity: 0.4;
  }

  .filter-checkbox-label-disabled{
    padding-left: 10px;
    font-size: 15px;
    opacity: 0.55;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #2D3640;

  }

  .filter-checkbox-empty{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: solid 1px #8b95a1;
  }

  .filter-checkbox-full{
    width: 12px;
    height: 12px;
    border-radius: 5px;
    border: solid 3px #00a575;

  }

  .filter-checkbox-label{
    padding-left: 10px;
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #5e6b79;

  }

  .filter-checkbox-label-active{
    padding-left: 10px;
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #5e6b79;

  }

}


.meta-options-box{
  display:flex;border-radius: 5px;
  border: solid 1px #bfc8d2;
  margin-top:30px;
  background-color: #fff; min-height: 250px; width: 100%;

  .meta-options-right{
    flex:2;
    background-color: #f3f5f7;
    border-top-left-radius: 4px;
    border-right:solid 1px #bfc8d2;
  }

  .meta-options-right-row-selected{
    background-color: white; border-top-left-radius: 4px;
    width: 101%; height: 60px; display: flex;
    align-items: center; border-bottom:solid 1px #bfc8d2;
  }

  .meta-options-right-row{
    border-top-left-radius: 4px; width: 100%; height: 60px;
    display: flex; align-items: center; border-right:solid 1px #bfc8d2;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }

  .meta-options-right-row-text{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 18px;font-weight: 500;

  }

  .check-icon-container{
    .check-icon /deep/ {
      width: 18px;
      height: 18px;
      path {
        fill: #00a575;
      }
    }
  }

  .meta-options-left{
    flex:5;
    .option-left-title{
      font-weight: 600;
      font-size: 15px;
      padding-top: 20px;
      padding-left: 30px;
    }

    .meta-options-left-form{
      margin-left: 30px;
      margin-top: 30px;
    }
  }

}

  .ses-dil-container{
    outline: 0;
    position: absolute;
    z-index: 999;

    width: 305px;
    height: 296px;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(45, 54, 64, 0.15);
    border: solid 1px #eff3f8;
    background-color: #ffffff;
    margin-top:2px;

    .ses-dil-temizle{
      cursor: pointer;
      color: #2d3640; font-size: 14px;
      &:hover{
        text-decoration: underline;
      }
    }

    .ses-dil-uygula{
      cursor: pointer;
      border-radius: 2px;
      border: none;
      background-color: #2d3640;
      padding: 3px auto 5px;
      color: #ffffff;
      letter-spacing: -0.3px; font-weight: 600; font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      height: 28px;
    }

    .ses-dil-checkbox-item{
      cursor: pointer;

      display: flex;
      width: 50%;
      margin-bottom: 33px;

      .ses-dil-checkbox-empty{
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: solid 1px #8b95a1;
      }

      .ses-dil-checkbox-full{
        width: 14px;
        height: 14px;
        border-radius: 5px;
        border: solid 4px #00a575;
      }

      .ses-dil-checkbox-label-empty{
        padding-left: 5px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5e6b79;
      }

    }
  }

  .service-type-change-button {
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px;
    color: #3894d6;
    cursor: pointer;
  }

  .title-static-text{
    position: absolute;
    color: #2d3640;
    font-size: 18px;
    margin-top: 30px;
    margin-left: 25px;
  }
  div{
    scroll-behavior: smooth;
  }


  .items{

    .item{
      position: relative;
      width: 250px;
      height: 181px;
      line-height: 0;
      margin-bottom: 30px;
      .item-img{
        cursor: move;
        width: 250px;
        height: 141px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .bottom{
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        background-color: #5e6b79;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .bottom-cover{
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        background-color: #fd4056;
      }

      .video-sound-icon-container{
        width: 40px;
        height: 40px;
        border-radius: 5px;
        background-color: rgba(45, 54, 64, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top:10px;
        left:10px;
        .video-sound-icon /deep/ {
          width: 22px;
          height: 22px;
          path {
            fill: #fff;
          }
        }
      }


      .delete-icon-container{
        margin-right: 10px;
        width: 26px;
        height: 26px;
        border-radius: 5px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .delete-icon /deep/ {
          width: 16px;
          height: 16px;
          path {
            fill: #2d3640;
          }
        }
        &:hover{
          opacity: 0.8;
        }
      }

      .bottom-text{
        margin-left: 5px;
        font-size: 12px;
        font-weight: 600;
        color: #eff3f8;
      }

      .star-icon /deep/ {
        margin-left: 15px;
        width: 20px;
        height: 20px;
        path {
          fill: #fff;
        }
      }
    }


    .make-cover-container{
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
    }

  }

  .upload-box{

    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 62px;
    width: 843px;
    height: 172px;
    border-radius: 10px;
    border: dashed 2px #bfc8d2;
    background-color: rgba(191, 200, 210, 0.1);

    .help-text{
      margin-top: 35px;
      font-size: 14px;
      font-weight: 600;
      color: #2d3640;

      span{
        padding-left: 5px;
        font-size: 14px;
        font-weight: 300;
        color: #8b95a1;
      }

      .emoji{
        padding-left: 20px;
        font-size: 18px;
        font-weight: normal;
        color: #2d3640;
      }
    }

    .upload-plus-icon /deep/ {
      width: 18px;
      height: 18px;
      path {
        fill: #fff;
        &:hover{
          opacity: 0.8;
        }
      }
    }

    &:hover{
      border: dashed 2px #fd4056;
      .cuper-black-button{
        background: #fd4056;
      }
    }
  }


  .a-inner{
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color:#ffbf00;


    width: 25px;
    height: 40px;
    top: -35px;
    position:relative;
    -moz-border-radius: 3px;
    border-radius: 3px;

  }

  .a-outer {
    z-index: 999999999;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 82px;

    overflow: hidden;

  }

  .arrow_box {

    border-radius: 2px;
    background: #ffbf00;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.46;
    width: 178px;
    height: 92px;
    color: #2d3640;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step-2-container{
    margin-top: 60px;
    .header-img{
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      width: 843px;
      height: 103px;
      background-repeat: no-repeat;
      background-size: 843px;
      border-radius:10px;
    }

    .step-2-text-container{
      margin-left: 108px;
    }
    .step-2-title{
      font-size: 18px;
      font-weight: 600;

      margin-top: 5px;
    }
    .step-2-sub-title{
      font-size: 16px;
      line-height: 1.25;

      span{
        font-weight: bold;
      }
    }




  }

  .sub-cat-box-container{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    width: 843px;
    justify-content: space-between;
    .sub-cat-box{
      cursor: pointer;
      margin-bottom: 30px;
      width: 393px;
      height: 79px;
      border-radius: 5px;
      border: solid 1px #bfc8d2;
      background-color: #ffffff;
      font-size: 18px;
      color: #8b95a1;
      display: flex;
      align-items: center;
      span{
        padding-left: 25px;
      }
      &:hover{
        border: solid 1px #00a575;
        background-color: #ffffff;
        color: #2d3640;
      }
    }

    .sub-cat-box-active{
      margin-bottom: 30px;
      width: 393px;
      height: 79px;
      border-radius: 5px;
      border: solid 1px #00a575;
      background-color: rgba(103, 204, 100, 0.1);
      font-size: 18px;
      color: #2d3640;
      display: flex;
      align-items: center;
      span{
        padding-left: 25px;
      }

    }
  }


  .bottom-fixed-div {
    position:fixed; bottom:0; width: 100%; background: linear-gradient(to right, #0e0e0f, #2d3740); height: 100px; z-index:11;
    left: 0;

    .continue-button{
      position: absolute;
      right: 0;
      background: #8b95a1; height: 100px; width: 200px;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.38px;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .continue-button-active{
      position: absolute;
      right: 0;
      cursor: pointer;
      -webkit-transition: all 0.18s ease-out;
      -moz-transition: all 0.18s ease-out;
      -ms-transition: all 0.18s ease-out;
      -o-transition: all 0.18s ease-out;
      transition: all 0.18s ease-out;

      height: 100px; width: 200px;
      background-color: #00a575;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.38px;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        height: 120px; width: 240px;

      }
    }

    .bottom-cat-img-container{
      cursor: pointer;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      width: 46px;
      height: 46px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .bottom-cat-img{
        width: 22px;
        height: 22px;
      }
    }

    .bottom-cat-title{
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.13;
      color: #ffffff;
      margin-left: 10px;

    }

    .bottom-centered{
      margin: 0 auto;
      width: 1440px;
      max-width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;


    }
    .step-title{
      font-size: 18px;
      font-weight: 600;
      line-height: 1.44;
      color: #ffffff;
      margin-bottom: 5px;
    }

    .step-sub-title{
      font-size: 24px;
      font-weight: 300;
      letter-spacing: -0.46px;
      color: #ffffff;
    }
  }




  .create-area{
    margin-left: 57px;
    margin-top: 30px;

    .title{
      font-size: 34px;
      color: #2d3640;
      margin-bottom: 14px;
    }
    .sub-title{
      font-size: 18px;
      line-height: 1.44;
      color: #6a7685;
      span{
        font-weight: bold;
        color: #2d3640;
      }
    }

    .main-cats-container{
      margin-top: 60px;
      display: flex;

      flex-wrap: wrap;
      .main-cats{
        margin-bottom: 30px;
      }

      .main-cats-bg{
        cursor: pointer;
        width: 215px; height: 190px;
        background-repeat: no-repeat;
        background-size: 190px 190px;

        .cat-title{
          padding-top: 106px;
          padding-left: 20px;
          padding-bottom: 5px;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.18;

        }
        .cat-sub-title{
          padding-left: 20px;
          opacity: 0.4;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.43;

          span{
            font-weight: bold;
          }
        }
      }

      img{
        width: 190px;
        height: 190px;
      }
    }
  }



  .sub-cat-type-kind-container{
    padding-top: 20px;
    .title{
      font-size: 26px;
      font-weight: 500;
      color: #2d3640;
      margin-top: 40px;
    }

    .help-text{
      margin-left: 5px;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      color: #5e6b79;
      span{
        font-weight: bold;

      }
    }

  }


</style>
